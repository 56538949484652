var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ViewLoadingMessage',{attrs:{"loading":_vm.firstLoading}}),(! _vm.firstLoading)?_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"main-header"},[_vm._v("Photo services")]),_c('CCard',{},[_c('CCardHeader',[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"mr-4"},[_vm._v("Found %{ totalResults } results")]),_c('CButton',{staticClass:"mr-4",attrs:{"color":"success","variant":"outline","size":"sm"},on:{"click":_vm.clearFilters}},[_c('translate',[_vm._v("Reset")])],1),(_vm.hasPerm('pica.add_photoservice'))?_c('div',{staticClass:"card-header-actions"},[_c('router-link',{attrs:{"to":{'name': 'photoServiceNew'}}},[_c('CButton',{attrs:{"block":"","color":"primary"}},[_c('translate',[_vm._v("New photo service")])],1)],1)],1):_vm._e()],1),_c('CCardBody',{staticClass:"px-0"},[_c('CDataTable',{staticClass:"custom-table custom-table--clean custom-table--has-filters",attrs:{"items":_vm.photoServices,"fields":_vm.fields,"hover":false,"striped":true,"bordered":true,"small":false,"fixed":false,"loading":_vm.loading,"no-items-view":{ noResults: this.$gettext('no filtering results available'), noItems: this.$gettext('Nessun evento trovato') },"column-filter":{ external: true, lazy: true },"column-filter-value":_vm.columnFilterValue},on:{"update:columnFilterValue":function($event){_vm.columnFilterValue=$event},"update:column-filter-value":[function($event){_vm.columnFilterValue=$event},function($event){return _vm.filterResults($event)}]},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h5',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("No photo services found")])])]},proxy:true},{key:"company_name",fn:function(ref){
var item = ref.item;
return [_c('th',[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.is_active)?_c('span',{staticClass:"text-success"},[_c('translate',[_vm._v("Active")])],1):_c('span',{staticClass:"text-warning"},[_c('translate',[_vm._v("Not active")])],1)])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"list-group list-group-horizontal mb-0"},[_c('router-link',{attrs:{"custom":"","to":{ name: 'PhotoServiceUsersManagerPhotographers', params: { photoServiceId: item.id }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('CLink',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$gettext('Manage photographers'), appendToBody: true, popperOptions: {}}),expression:"{content: $gettext('Manage photographers'), appendToBody: true, popperOptions: {}}"}],staticClass:"mr-4 list-inline-item list-group-item-light",on:{"click":navigate}},[_c('CIcon',{attrs:{"name":"cipUsers","size":"custom-size","height":24}})],1)]}}],null,true)}),_c('router-link',{attrs:{"custom":"","to":{ name: 'PhotoServiceUsersManagerPhotographerManagers', params: { photoServiceId: item.id }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('CLink',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$gettext('Manage photographer managers'), appendToBody: true}),expression:"{content: $gettext('Manage photographer managers'), appendToBody: true}"}],staticClass:"mr-4 list-inline-item list-group-item-light",on:{"click":navigate}},[_c('CIcon',{attrs:{"name":"cipSlider","size":"custom-size","height":24}})],1)]}}],null,true)}),_c('router-link',{attrs:{"custom":"","to":{ name: 'PhotoServiceUsersManagerLegalRepresentative', params: { photoServiceId: item.id }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('CLink',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$gettext('Manage legal representative'), appendToBody: true}),expression:"{content: $gettext('Manage legal representative'), appendToBody: true}"}],staticClass:"list-inline-item list-group-item-light",on:{"click":navigate}},[_c('CIcon',{attrs:{"name":"cipVoice","size":"custom-size","height":24}})],1)]}}],null,true)})],1)])]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"text-right"},[(_vm.hasPerm('pica.change_photoservice'))?_c('CButton',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"color":"primary","variant":"outline"},on:{"click":function($event){return _vm.routeToDetail(item)}}},[_vm._v(" Edit ")]):_vm._e()],1)])]}}],null,false,3719953688)})],1),(_vm.totalPages > 1)?_c('CCardFooter',[_c('CPagination',{attrs:{"active-page":_vm.page,"pages":_vm.totalPages},on:{"update:activePage":[function($event){return _vm.fetchResults({page: $event})},function($event){_vm.page=$event}],"update:active-page":function($event){_vm.page=$event}}})],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }