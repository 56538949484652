<template>
  <div>
    <ViewLoadingMessage :loading="firstLoading"/>

    <CRow v-if="! firstLoading">
      <CCol lg="12">
        <h1 class="main-header" v-translate>Photo services</h1>
        <CCard class="">

          <CCardHeader>


            <span class="mr-4" v-translate>Found %{ totalResults } results</span>
            <CButton @click="clearFilters" color="success" variant="outline" size="sm" class="mr-4">
              <translate>Reset</translate>
            </CButton>
            <div class="card-header-actions" v-if="hasPerm('pica.add_photoservice')">
              <router-link :to="{'name': 'photoServiceNew'}">
                <CButton block color="primary">
                  <translate>New photo service</translate>
                </CButton>
              </router-link>
            </div>


          </CCardHeader>

          <CCardBody class="px-0">
            <CDataTable
                :items="photoServices"
                :fields="fields"
                :hover="false"
                :striped="true"
                :bordered="true"
                :small="false"
                :fixed="false"
                :loading="loading"
                :no-items-view="{ noResults: this.$gettext('no filtering results available'), noItems: this.$gettext('Nessun evento trovato') }"
                :column-filter="{ external: true, lazy: true }"
                :column-filter-value.sync="columnFilterValue"
                @update:column-filter-value="filterResults($event)"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No photo services found</h5>
                </div>
              </template>

              <template #company_name="{ item }">
                <th>
                  {{ item.company_name }}
                </th>
              </template>

              <template #status="{ item }">
                <td>
                  <span class="text-success" v-if="item.is_active">
                      <translate>Active</translate>
                  </span>
                  <span class="text-warning" v-else>
                    <translate>Not active</translate>
                   </span>
                </td>
              </template>

              <template #actions="{ item }">
                <td>
                  <div class="list-group list-group-horizontal mb-0">

                    <router-link v-slot="{navigate}" custom
                                 :to="{ name: 'PhotoServiceUsersManagerPhotographers', params: { photoServiceId: item.id }}">
                      <CLink class="mr-4 list-inline-item list-group-item-light" @click="navigate"
                             v-c-tooltip="{content: $gettext('Manage photographers'), appendToBody: true, popperOptions: {}}">
                        <CIcon name="cipUsers" size="custom-size" :height="24"/>
                      </CLink>
                    </router-link>


                    <!-- TODO: nascondere ai photographer manager -->
                    <router-link v-slot="{navigate}" custom
                                 :to="{ name: 'PhotoServiceUsersManagerPhotographerManagers', params: { photoServiceId: item.id }}">
                      <CLink class="mr-4 list-inline-item list-group-item-light" @click="navigate"
                             v-c-tooltip="{content: $gettext('Manage photographer managers'), appendToBody: true}">
                        <CIcon name="cipSlider" size="custom-size" :height="24"/>
                      </CLink>
                    </router-link>


                    <router-link v-slot="{navigate}" custom
                                 :to="{ name: 'PhotoServiceUsersManagerLegalRepresentative', params: { photoServiceId: item.id }}">
                      <CLink @click="navigate" class="list-inline-item list-group-item-light"
                             v-c-tooltip="{content: $gettext('Manage legal representative'), appendToBody: true}">
                        <CIcon name="cipVoice" size="custom-size" :height="24"/>
                      </CLink>
                    </router-link>


                  </div>
                </td>
              </template>
              <template #edit="{ item }">
                <td>
                  <div class="text-right">
                    <CButton color="primary" @click="routeToDetail(item)" v-translate variant="outline"
                             v-if="hasPerm('pica.change_photoservice')">
                      Edit
                    </CButton>


                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>

          <CCardFooter v-if="totalPages > 1">
             <CPagination
                @update:activePage="fetchResults({page: $event})"
                :active-page.sync="page"
                :pages="totalPages "/>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import router from '../../router'
import ViewLoadingMessage from "@/components/ViewLoadingMessage"
import PhotoServiceService from "@/services/photo_service.service"
// import Multiselect from "vue-multiselect"

export default {
  name: 'PhotoServiceList',

  components: {
    // Multiselect,
    ViewLoadingMessage
  },
  beforeDestroy() {
    // hide manually TT attached to the Body
    // TT are attached to the body to avoid that button groups are displayed with wrong styles
    const tts = document.getElementsByClassName('bs-tooltip-old-auto');
    for (const i in tts) {
      if (tts[i].style) {
        tts[i].style.visibility = 'hidden';
      }
    }
  },

  data() {
    return {
      pagination: {},
      photoServices: [],

      loading: false,
      firstLoading: true,
      columnFilterValue: {},
      fields: [
        {
          key: 'id',
          label: 'ID',
          _style: 'width:100px;'
        },
        {
          key: 'company_name',
          label: this.$gettext('Photo service'),
        },
        {
          key: 'status',
          label: this.$gettext('Status'),
          filter: false,
        },
        {
          key: 'actions',
          label: this.$gettext('Actions'),
          sorter: false,
          filter: false,
        },
        {
          key: 'edit',
          label: '',
          sorter: false,
          filter: false,
          _style: 'width:250px;'
        }
      ]
    }
  },

  mounted() {
    this.fetchResults().then(() => this.firstLoading = false)
  },

  computed: {
    totalResults() {
      return this.pagination.total
    },
    hasResults() {
      return this.totalResults > 0
    },

    totalPages() {
      return this.pagination.num_pages
    },

    page: {
       get(){
         return this.pagination.page_number
       },
       set(val){
         return val
       }
    },
  },

  methods: {
    fetchResults(params) {
      this.loading = true
      const p = {...this.columnFilterValue, ...params}
      return PhotoServiceService.getPhotoServices(p)
          .then((response) => {

            this.photoServices = response.data.results
            this.pagination = response.data.pagination
            this.loading = false
          })
    },

    filterResults(params) {
      console.log('filterResults', params)
      this.fetchResults()
    },

    clearFilters() {
      this.columnFilterValue = {}
      this.fetchResults()
    },

    routeToDetail(item) {
      // this.$route.meta.title = 'fabio title'
      router.push({name: 'photoServiceDetail', params: {photoServiceId: item['id']}})
    },
  }
}
</script>

<style lang="scss">
.list-group-item-light {
  background-color: transparent;
}
</style>
